const initialState = {
    items: [],
    messages: [],
    currentDialogId: window.location.pathname.split('dialog/')[1],
    dialogID: window.localStorage.chatDialogId,
    status: parseInt(window.localStorage.chatStatus),
    isLoading: false,
    operator: {
        "avatar": "",
        "name": ""
    },
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case 'DIALOGS:SET_ITEMS':
            return {
                ...state,
                items: payload,
            };
        case 'DIALOGS:SET_OPERATOR':
            return {
                ...state,
                operator: payload,
            };
        case 'DIALOGS:SET_CURRENT_DIALOG_ID':
            console.log("DIALOGS:SET_CURRENT_DIALOG_ID")
            return {
                ...state,
                dialogID: payload,
            };
        case 'CHAT:STATUS':
            console.log("CHAT:STATUS")
            return {
                ...state,
                status: payload,
            };
        case 'MESSAGES:ADD_MESSAGE':
            return {
                ...state,
                messages: [...state.items, payload],
            };
        case 'MESSAGES:SET_ITEMS':
            return {
                ...state,
                messages: payload,
                isLoading: false,
            };
        case 'DIALOGS:LAST_MESSAGE_READED_STATUS':
            return {
                ...state,
                messages: state.messages.map(message => {
                    if (message.dialog._id === payload.dialogId) {
                        message.readed = true;
                    }
                    return message;
                }),
            };
        case 'MESSAGES:SET_IS_LOADING':
            return {
                ...state,
                isLoading: payload,
            };
        default:
            return state;
    }
};
