import React, {useEffect, useRef, useState} from 'react';
import Launcher from '../component/Launcher';
import {dialogsActions} from "../redux/actions";
import {connect} from 'react-redux';
import socket from '../core/socket';
import store from "../redux/store";


const Layout = ({items, fetchMessages, dialogID}) => {

    const [isOpen, setOpen] = useState(localStorage.getItem("chatIsOpen") === "1");
    const messagesRef = useRef(null);

    useEffect(() => {
        if (dialogID) {
            fetchMessages(dialogID);
        }
        socket.on('SERVER:NEW_MESSAGE', fetchMessages);

        return () => socket.removeListener('SERVER:NEW_MESSAGE', fetchMessages);
    }, [dialogID]);

    const closeDialog = (dialog_id) => {
        console.log("chatStatus", localStorage.getItem("chatStatus"));
        if (dialog_id === dialogID && localStorage.getItem("chatStatus") !== "0") {
            console.log("close_dialog", dialog_id);
            store.dispatch(dialogsActions.setOperatorParams("", ""));
            store.dispatch(dialogsActions.setCurrentDialogId("0"));
        }
    }

    useEffect(() => {
        socket.on('SERVER:DIALOG_CLOSED', (e => {
            let dialog_id = e.dialog.id;
            closeDialog(dialog_id);
        }));
        return () => socket.removeListener('SERVER:DIALOG_CLOSED', closeDialog);
    }, [dialogID])

    useEffect(() => {
        if (messagesRef.current != null) {
            messagesRef.current.scrollTo(0, 999999);
        }
    }, [items]);

    const handleClick = () => {
        setOpen(!isOpen);
        localStorage.setItem("chatIsOpen", !isOpen ? "1" : "0");
    }

    return <div>
        <Launcher
            messageList={items}
            newMessagesCount={0}
            handleClick={handleClick}
            isOpen={isOpen}
        />
    </div>;
}

export default connect(
    ({dialogs, user, attachments}) => ({
        dialogID: dialogs.dialogID,
        items: dialogs.messages,
        isLoading: dialogs.isLoading,
        attachments: attachments.items,
        user: user.data,
    }),
    dialogsActions,
)(Layout);
