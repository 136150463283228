import axios from "axios";
import Const from "./Const";

axios.defaults.baseURL = Const.URL;

axios.defaults.headers.common["token"] = localStorage.getItem("chatToken");

window.axios = axios;

export default axios;
