import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translationUz from "../locales/uz/translation"
import translationRu from "../locales/ru/translation"
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

let currentLang = "uz";

if (localStorage.getItem("i18nextLng")) {
    currentLang = localStorage.getItem("i18nextLng");
}

const resources = {
    uz: {translation: translationUz},
    ru: {translation: translationRu}
};

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        lng: currentLang,
        fallbackLng: currentLang,
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
