import React, {useEffect, useState} from 'react';
import {Formik} from 'formik';
import Yup from "../utils/YupLocale";
import {connect} from "react-redux";
import store from '../redux/store';
import {userActions} from '../redux/actions';
import i18n from "../utils/i18n.js"
import _ from "lodash"
import {withTranslation} from "react-i18next";
import {axios} from "../core";


const GuestDialog = ({t}) => {

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    let activeLang = localStorage.getItem("i18nextLng");

    const genders = [
        {
            "id": "male", "name": t("Эркак")
        },
        {
            "id": "female", "name": t("Аёл")
        },
    ];

    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);

    useEffect(() => {
        axios.get("/selector").then(data => {
            setRegions(data.data.regions);
            setDistricts(data.data.districts);
        })
    }, [])

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    Yup.addMethod(Yup.string, "districtValidation", function (errorMessage) {
        return this.test(`test-district-length`, errorMessage, function (value) {
            const { path, createError } = this;

            console.log(value);

            return (
                (value === "0") ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const schema = Yup.object({
        last_name: Yup.string().required().label(t("Фамилия ва исм")),
        age: Yup.string().required().label(t("Ёшингиз")),
        phone: Yup.string().matches(phoneRegExp, t("Телефон рақамни нотўғри киритингиз")).required().label(t("Телефон")),
        sex: Yup.string().required().label(t("Жинсингиз")),
        region_id: Yup.string().trim().label(t("Вилоят")),
        district_id: Yup.string().min(1, t("Туманни танланг")).required("Туманни танланг").label(t("Шахар ёки туман")),
    });

    const handleSubmit = (values, setSubmitting) => {
        store
            .dispatch(userActions.fetchUserLogin(values))
            .then(({status}) => {
                console.log(status);
                if (status === 'success') {
                    //props.history.push('/');
                    //window.location = '/';
                } else {
                    console.log("error");
                }
                setSubmitting(false);
            })
            .catch((error) => {
                console.log(error);
                setSubmitting(false);
            });
    }


    const districtFilter = region_id => {
        return districts.filter(e => e.parent_id === region_id);
    }

    return (
        <>
            <div className="sc-language">
                <button onClick={() => changeLanguage('uz')} className="lang">O'zbek tilida</button>
                <button onClick={() => changeLanguage('ru')} className="lang">На русском</button>
            </div>
            <div className="message--form">
                <Formik
                    validationSchema={schema}
                    initialValues={{
                        first_name: "EMPTY",
                        last_name: '',
                        age: 16,
                        phone: '',
                        sex: "male",
                        region_id: '',
                        district_id: "",
                    }}
                    onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="message--form_group">
                                <label>{t("Фамилия ва исм")}</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.last_name}
                                />
                                <div className="message--form_info">
                                    {errors.last_name && touched.last_name && errors.last_name}
                                </div>
                            </div>
                            <div className="message--form_group">
                                <label>{t("Телефон")}</label>
                                <input
                                    type="text"
                                    name="phone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}
                                />
                                <div className="message--form_info">
                                    {errors.phone && touched.phone && errors.phone}
                                </div>
                            </div>
                            <div className="message--form_group">
                                <label>{t("Ёшингиз")}</label>
                                <select
                                    name="age"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.age}
                                >
                                    {
                                        _.range(16, 75).map(e => <option key={Math.random()} value={e}>{e}</option>)
                                    }
                                </select>
                                <div className="message--form_info">
                                    {errors.age && touched.age && errors.age}
                                </div>
                            </div>
                            <div className="message--form_group">
                                <label>{t("Жинсингиз")}</label>
                                <select
                                    name="sex"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.sex}
                                >
                                    {
                                        genders.map(e => <option key={Math.random()} value={e.id}>{e.name}</option>)
                                    }
                                </select>
                                <div className="message--form_info">
                                    {errors.sex && touched.sex && errors.sex}
                                </div>
                            </div>
                            <div className="message--form_group">
                                <label>{t("Туман")}</label>
                                <select
                                    name="district_id"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.district_id}
                                >
                                    <option value="" key="0" disabled="disabled">{t("Туманни танланг")}</option>
                                    {
                                        regions.map(e => {
                                            return <optgroup key={e._id} label={(activeLang === "ru" ? e.name_ru : e.name_uz)}>
                                                {
                                                    districtFilter(e._id).map(item => <option key={Math.random()} value={item._id}>{(activeLang === "ru" ? item.name_ru : item.name_uz)}</option>)
                                                }
                                            </optgroup>
                                        })
                                    }
                                </select>
                                <div className="message--form_info">
                                    {errors.district_id && touched.district_id && errors.district_id}
                                </div>
                            </div>
                            <input type="hidden" name="first_name" onChange={handleChange} value={values.first_name}/>
                            <button type="submit" disabled={isSubmitting}>
                                {t("Киритиш")}
                            </button>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default withTranslation()(connect(() => (
    {...userActions}
))(GuestDialog));
