import React from 'react';
import Linkify from "react-linkify";
import FileIcon from "./icons/FileIcon";
import Const from "../core/Const";
import {connect} from "react-redux";
import {dialogsActions} from "../redux/actions";
import store from "../redux/store";

const Message = ({item, attachments, user}) => {

    let u_id = item.user ? item.user.id : user._id;
    let userID = user ? user._id : 0

    let contentClassList = [
        'sc-message--content',
        (u_id === userID ? 'sent' : 'received')
    ];

    const renderAttachment = item => {
        item.url = Const.URL + "/" + item.url;

        return (
            <a key={Math.random()} className="sc-message--file" href={item.url} download={item.filename}>
                <FileIcon/>
                <p>{item.filename}</p>
            </a>
        );
    };

    if (item.text.length === 0) {
        return "";
    }

    let avatar = '/assets/chat-icon.png';

    if (u_id !== userID) {
        store.dispatch(dialogsActions.setOperatorParams(item.user.last_name + " " + item.user.first_name, item.user.avatar));

        if (item.user.avatar) {
            avatar = Const.URL + "/" + item.user.avatar;
        }
    }

    return (
        <div className="sc-message">
            <div className={contentClassList.join(' ')}>
                <div className="sc-message--avatar" style={{
                    backgroundImage: `url(${avatar})`
                }}/>
                <div className="sc-message--text">{<Linkify properties={{target: '_blank'}}>{item.text}</Linkify>}</div>

                {attachments && attachments.map(item => renderAttachment(item))}
            </div>
        </div>
    );
};


export default connect(() => (
    {...dialogsActions}
))(Message);
