import React, {useState} from 'react';
import ReactStars from "react-rating-stars-component";
import {connect} from "react-redux";
import {dialogsActions} from "../redux/actions";
import {withTranslation} from "react-i18next";

const StarDialog = ({fetchStar, t}) => {

    const [rating, setRating] = useState(4);
    const [text, setText] = useState("");

    const handleText = (e) => {
        setText(e.target.value);
    }

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    const sendRating = () => {
        fetchStar({vote: rating, text: text});
    }

    return (
        <div className="user-start">
            <p>{t("Бизнинг жавоблар сиз учун фойдали бўлдими?")}</p>
            <ReactStars
                classNames="user-star"
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
            />
            <div className="message--form">

                <div className="message--form_group message--w100">
                    <textarea value={text} className="form-control" name="text" onChange={handleText} placeholder={t("Ўзингизга керакли жавоб олдингизми?")}/>
                </div>

                <button type="submit" onClick={sendRating}>
                    {t("Жўнатиш")}
                </button>
            </div>
        </div>
    );
};

export default withTranslation()(connect(({dialogs}) => ({dialogs,}), {...dialogsActions})(StarDialog));
