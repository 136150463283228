import React from 'react';
import MessageList from './MessageList';
import UserInput from './UserInput';
import Header from './Header';
import GuestDialog from "./GuestDialog";
import {connect} from "react-redux";
import CategoryDialog from "./CategoryDialog";
import StarDialog from "./StarDialog";

const ChatWindow = (
    {
        messageList,
        isOpen,
        onClose,
        isAuth,
        status
    }
) => {

    let classList = [
        'sc-chat-window',
        isOpen ? 'opened' : 'closed'
    ];

    return (
        <div className={classList.join(' ')}>
            <Header
                onClose={onClose}
            />
            {
                isAuth ?
                    <>
                        {
                            status === 1 || status === "1" || status === 2 || status === "2" ?
                                <>
                                    <MessageList
                                        messages={messageList}
                                    />
                                    {
                                        status === 2 || status === "2" ? <StarDialog/> : <UserInput/>
                                    }
                                </>
                                :
                                <CategoryDialog/>
                        }
                    </>
                    :
                    <GuestDialog/>
            }
        </div>
    );
}

export default connect(({user, dialogs}) => ({isAuth: user.isAuth, dialogID: dialogs.dialogID, status: dialogs.status}))(ChatWindow);
