import React, {useEffect, useState} from 'react';
import ChatWindow from './ChatWindow';
import {withTranslation} from "react-i18next";

const Launcher = ({
                      messageList,
                      handleClick,
                      newMessagesCount,
                      onMessageWasSent,
                      onFilesSelected,
                      isOpen,
    t
                  }) => {

    const [setOpenClass] = useState(false);

    useEffect(() => {
        //console.log(messageList);
        if (messageList.length > 0) {
            //console.log(messageList);
            // console.log("messageList", array);
        }

        //playIncomingMessageSound()
    }, [messageList])

    const playIncomingMessageSound = () => {
        let audio = new Audio("/assets/sounds/notification.mp3");
        audio.play().then(r => console.log(r));
    }

    const handleClick2 = () => {
        if (handleClick !== undefined) {
            handleClick();
        } else {
            setOpenClass(!isOpen);
        }
    }

    const classList = [
        'sc-launcher',
        (isOpen ? 'opened' : ''),
    ];

    return (
        <div id="sc-launcher">
            <div className={classList.join(' ')} onClick={handleClick2}>
                <MessageCount count={newMessagesCount} isOpen={isOpen}/>
                <div className="sc-icon">
                    <img className={'sc-open-icon'} src="/assets/support.png" alt=""/>
                    <img className={'sc-closed-icon'} src="/assets/support.png" alt=""/>
                </div>
                <div className="help">
                    <h3>{t("Ёрдам керакми?")}</h3>
                </div>
            </div>
            <ChatWindow
                messageList={messageList}
                onUserInputSubmit={onMessageWasSent}
                onFilesSelected={onFilesSelected}
                isOpen={isOpen}
                onClose={handleClick2}
            />
        </div>
    );
}

const MessageCount = ({count, isOpen}) => {
    if (count === 0 || isOpen === true) {
        return null;
    }
    return (
        <div className={'sc-new-messages-count'}>
            {count}
        </div>
    );
};

Launcher.defaultProps = {
    newMessagesCount: 0,
};

export default withTranslation()(Launcher);
