import {userApi} from '../../utils/api';

const Actions = {
    setUserData: data => ({
        type: 'USER:SET_DATA',
        payload: data,
    }),
    setIsAuth: bool => ({
        type: 'USER:SET_IS_AUTH',
        payload: bool,
    }),
    fetchUserData: () => dispatch => {
        userApi
            .getMe()
            .then(({data}) => {
                dispatch(Actions.setUserData(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        Actions.setIsAuth(false);
                        localStorage.removeItem("chat_token");
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    },
    fetchUserLogin: postData => dispatch => {
        return userApi
            .signUp(postData)
            .then(({data}) => {
                const {token} = data;
                window.axios.defaults.headers.common['token'] = token;
                localStorage.setItem("chatStatus", "0");
                localStorage.setItem("chatToken", token);
                dispatch(Actions.fetchUserData());
                dispatch(Actions.setIsAuth(true));
                return data;
            })
            .catch(({response}) => {
                console.log(response);
            });
    },
};

export default Actions;
