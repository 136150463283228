import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from 'react-i18next';
import Const from "../core/Const";

const Header = ({onClose, operator, t}) => {

    let avatar = "/assets/chat-icon.png";
    let name = t("title");

    if (operator.avatar !== undefined) {
        if(operator.avatar.length === 0){
            avatar = '/assets/support.png'
        }else {
            avatar = Const.URL + "/" + operator.avatar;
        }
    }

    if (operator.name.length) {
        name = operator.name;
    }

    return (
        <div className="sc-header">
            <img className="sc-header--img" src={avatar} alt=""/>
            <div className="sc-header--team-name">{name}</div>
            <div className="sc-header--close-button" onClick={onClose}>
                <img src="/assets/close-icon.png" alt=""/>
            </div>
        </div>
    );
}

export default withTranslation()(connect(({dialogs}) => ({operator: dialogs.operator}))(Header));
